<template>
  <div id="app">

    <MarqueeText class="top-marquee bpm" :duration="bpm/10" :repeat="10">

      <div class="single-bpm">

        <div>
          My heart will go online
        </div>

        <svg class="separator" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"/>
        </svg>

        <div>Now playing <strong>{{ bpm }}</strong> heart beats per minute</div>

        <svg class="separator" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"/>
        </svg>

      </div>

    </MarqueeText>


    <MarqueeText :reverse="true" class="bottom-marquee bpm" :duration="bpm/10" :repeat="10">

      <div class="single-bpm">

        <div>
          My heart will go online
        </div>

        <svg class="separator" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd"
                d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                clip-rule="evenodd"/>
        </svg>

        <div>Now <strong>{{ bpm }}</strong> BPM</div>

        <svg class="separator" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd"
                d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                clip-rule="evenodd"/>
        </svg>

      </div>

    </MarqueeText>

    <AudioAndLyrics :audio="audio" :bpm="bpm"/>


  </div>
</template>

<script>

import axios from "axios";
import AudioAndLyrics from "./components/AudioAndLyrics";
import MarqueeText from 'vue-marquee-text-component'

export default {
  name: 'App',
  components: {
    AudioAndLyrics,
    MarqueeText
  },
  data() {
    return {

      audio: null,
      last: {},

      server_bpm: null,
      bpm: null,

      bpmInterval: 0
    }
  },


  async created() {

    var resp = await axios.get('https://api.guidosegni.com:8002/api/bpm/last');
    this.last = resp.data.data;
    this.server_bpm = resp.data.data.bpm;
    this.variate();

    //window.addEventListener('click', this.fullscreen)


  },

  mounted() {

    var channel = this.$pusher.subscribe('iLeftMyHeartOnline');

    channel.bind('heart', ({bpm}) => {

      //console.log('Un palpito reale del cuore', bpm);
      this.server_bpm = bpm;

    });


  },

  methods: {

    variate() {

      var variation_index = 10;
      this.bpm = parseInt(this.server_bpm) + (Math.round(Math.random() * variation_index) - (variation_index / 2))
      var next = 5000 + Math.random() * 10000;
      console.log("variation in " + next + "ms");
      setTimeout(this.variate, next)

    },

    fullscreen() {

      var elem = document.documentElement;

      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) { /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE11 */
        elem.msRequestFullscreen();
      }

    }


  },

  watch: {

    server_bpm(n) {
      this.bpm = n;
    }

  }


}
</script>
<style>
html, body {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Manrope', sans-serif;
  background: #0f0;
  font-size: 10.5vh;
  line-height: 1;
  color: white;
  font-weight: lighter;

  display: flex;

  justify-content: flex-start;
  align-items: center;

  height: 100vh;

  padding: 1rem;
  margin: 0;
  box-sizing: border-box;

  overflow: hidden;


  animation-name: backgroundColorPalette;
  animation-duration: 30s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;


}

.lyrics {
  text-shadow: 0 0 30px rgba(255, 255, 255, .9);
}


.top-marquee {
  position: fixed;
  top: 1rem;
  left: 1rem;
  width: calc(100% - 2rem);
  font-family: 'Manrope', sans-serif;
  font-size: 4vh;

}

.bpm {
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  width: calc(100% - 2rem);
  font-family: 'Manrope', sans-serif;
  font-size: 4vh;


}

.single-bpm, .flex-row {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}


.separator {
  width: 4vh;
  height: 4vh;
  margin: 0 2vh;
}

.lyrics {
  animation: lyricsFx 30s infinite alternate;
}

@keyframes lyricsFx {
  from {
    transform: scale(.8);
  }
  to {
    transform: scale(1);
    transform-origin: center;
  }

}

@keyframes backgroundColorPalette {
  0% {
    background: #0f0;
  }
  25% {
    background: #b88dfc;
  }
  50% {
    background: #fffb00;
  }
  75% {
    background: #ff0000;
  }
  100% {
    background: #0036ff;
  }
}


#btn {
  cursor: pointer;
  font-size: 3vh;
  line-height: 1.45;
  display: block;
}

#btn strong {
  font-size: 3.25vh;
  border-bottom: 2px solid;
  line-height: 1.8;
  margin-bottom: 1rem;

}

.block {display: block}

.cta {
  margin-top: 1rem;
}

.author {
  display: block;
  margin-bottom: .25rem;
  font-weight: lighter;
  font-size: 1.125rem;
}

.lyrics-wrapper {
  position: relative;
  z-index: 100;
}

</style>