<template>

  <div class="lyrics-wrapper">
    <div v-if="audio">
      <div class="lyrics" v-if="currentPart">
        {{ currentPart.part }}
      </div>

    </div>
    <div v-else>
      <a id="btn" class="btn" @click="play()">

        <small class="author">Guido Segni (2021 - 2022)</small>


        <strong>My heart will go online ️</strong> <br>

        <small class="block">(or how I disclose my heart using Celine Dion's words)</small>

        <small class="block cta">Turn up the volume and tap to listen ️</small>
      </a>
    </div>
  </div>

</template>

<script>

const vttToJson = require("vtt-to-json");
import vttString from '!raw-loader!../lyrics.vtt'

/*

vttToJson(vttString)
    .then((result) => {
      console.log(result)
    });*/


export default {
  name: 'AudioAndLyrics',
  props: {
    bpm: {
      default:0
    },
    msg: String
  },

  data() {
    return {
      audio : null,
      lyrics : null,
      currentSubtitle : null,
      currentPart: null
    }
  },

  async created() {

    this.lyrics = await vttToJson(vttString);




  },

  watch: {

    bpm() {

      this.onChangeBpm();
    }

  },


  methods: {

    async play() {
      this.audio = new Audio('/heart.mp3');
      this.audio.loop = true;
      this.audio.play();

      this.audio.addEventListener("timeupdate", () => {

        //console.log("currentTime", this.audio.currentTime  )

        this.currentPart = this.lyrics.find((element) => {
          return this.audio.currentTime >= element.start/1000 && this.audio.currentTime <= element.end/1000;
        })

      });

    },

    bpm2rate(value) {

      // return value / 100;
      return (value >= 95) ? value / 80 : value / 110;

    },

    onChangeBpm() {

      if (!this.audio) return;
      this.audio.preservesPitch = false;
      this.audio.mozPreservesPitch = false;
      this.audio.webkitPreservesPitch = false;

      this.audio.playbackRate = this.bpm2rate(this.bpm);


    },



  }


}
</script>
