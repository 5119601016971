import Vue from 'vue'
import App from './App.vue'
import VuePusher from 'vue-pusher'

Vue.use(VuePusher, {
  api_key: '9054f3754b461dccf227',
  options: {
      cluster: 'eu',
      //encrypted: true,
  }
})

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
